.course-wrapper {
    padding-left: 90px;
}

@media screen and (max-width: 690px) {
    .course-wrapper {
        padding-left: 120px;
    }
}

@media screen and (max-width: 590px) {
    .course-wrapper {
        padding-left: 60px;
    }
}

@media screen and (max-width: 490px) {
    .course-wrapper {
        padding-left: 40px;
    }
}

@media screen and (max-width: 390px) {
    .course-wrapper {
        padding-left: 0px;
    }
}
